// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    //in working 
    //baseUrl: 'http://rfq.edashboard.Client.Ipsosindia.in/',
    //baseUrlMain: 'http://edashboard.Ipsosindia.in/'
    baseurl: 'http://localhost:18292/',

    baseurlmain: 'http://localhost:18293/'

    //baseUrl: 'http://requestmanagementipsos.excavateservices.com/',
    //baseUrlMain: 'http://requestmanagementgoogle.excavateservices.com/'
    //baseUrl: 'http://rmclient.edashboard.in/',
    //baseUrlMain:'http://rmmain.edashboard.in/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
