import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Platform, NavController, MenuController } from '@ionic/angular';
import { AuthService } from './auth.service';
import { AppConfig } from './app.config';
import { IonicService } from './ionic.service';
import { GlobalService } from './global.service';
import { GenericService } from './generic.service';

@Injectable()
export class AuthGuard implements CanActivate
{
    constructor(public authService: AuthService, private platform: Platform,
        private navCtrl: NavController, private menu: MenuController) { }

    canViewPage(path)
    {
        if (!GlobalService.user)
        {
            this.navigate('/login');
            return false;
        }

        path = path.split('?')[0];
        if (path == '/login')
            return true;

        let page = GlobalService.getMenuItemByPath(path);
        if (page)
        {
            if (page.webOnly && AppConfig.isApp)
                return false;

            if (page.appOnly && !AppConfig.isApp)
                return false;

            if (page.role && !AuthService.hasRole(page.role))
                return false;

            if (page.permission && !AuthService.hasPermission(page.permission))
                return false;
        }
        return true;
    }

    async canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot)
    {
        await this.initApp();
        await this.authService.isLoggedin();

        let canView = await this.canViewPage(state.url);
        return canView;
    }

    async initApp()
    {
        if (AppConfig.isApp && !AppConfig.isAppLoaded)
        {
            await this.platform.ready();
            AppConfig.isAppLoaded = true;
        }
    }

    navigateToDefaultPage()
    {
        if (!GlobalService.user)
            this.navigate('/login');
        else
        {
            //this.menu.enable(true);
            //this.navigate('/home/0');
        }
    }

    navigate(url, params = null, queryParams = null)
    {
       let path = [url];
        if (params)
            path.push(params);

        queryParams = queryParams || {};
        //Object.assign(queryParams, { r: GlobalService.user.surveyCode });

        this.navCtrl.navigateForward(path, { queryParams: queryParams });
    }
}