import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { UploadService } from './providers/upload.service';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './providers/auth.service';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { TokenService } from './providers/token.service';
import { HttpClientModule } from '@angular/common/http';
import { GenericService } from './providers/generic.service';
import { AjaxService } from './providers/ajax.service';
import { AuthGuard } from './providers/auth-guard';
import { AppConfig } from './providers/app.config';
import { ChartService } from './providers/chart.service';
import { RegexService } from './providers/regex.service';
import { FileService } from './providers/file.service';
import { ImageService } from './providers/image.service';
import { LogService } from './providers/log.service';
import { NetworkService } from './providers/network.service';
import { DataService } from './providers/data.service';
import { DataService2 } from './providers/data.service2';
import { ThemeService } from './providers/theme.service';
import { GlobalErrorHandler } from './providers/global-error.handler';
import { IonicService } from './providers/ionic.service';
import { GlobalService } from './providers/global.service';
import { Network } from '@ionic-native/network/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipsModule } from 'ionic-tooltips';
import { 
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule
} from '@angular/material';
import {MatTabsModule} from '@angular/material/tabs';
import { PipesModule } from './pipes/pipes.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export function getJwtHttp(tokenService: TokenService)
{
    return {
        tokenName: 'access_token',
        tokenGetter: (() =>
        {
            return tokenService.getAccessToken();
        }),
        globalHeaders: [{ 'Content-Type': 'application/json' }]
    };
}



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
      BrowserModule,
      PipesModule,
    MatNativeDateModule,
    MatInputModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
      IonicModule.forRoot(),
      NgbModule,
    /*  TooltipsModule.forRoot(),*/
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    JwtModule.forRoot(
      {
          jwtOptionsProvider:
          {
              provide: JWT_OPTIONS,
              useFactory: getJwtHttp,
              deps: [TokenService]
          }
      }),
      BrowserAnimationsModule,
      MatPaginatorModule,
      CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [
    StatusBar,
    AppConfig,
    MatDatepickerModule,
    MatNativeDateModule,
        GenericService,
        AjaxService,
        AuthService,
        AuthGuard,
        TokenService,
        ChartService,
        RegexService,
        FileService,
        ImageService,
        LogService,
        NetworkService,
        IonicService,
      DataService,
      DataService2,
      UploadService,
        GlobalService,
        GlobalErrorHandler,
        ThemeService,
        Network,
    SplashScreen,
    AuthService,
      TokenService,      
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
