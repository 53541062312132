import { Injectable } from '@angular/core';

declare var themes: any;

var globalConfig =
{
    baseUrl: "http://localhost:18292/",
    baseUrlMain: "http://localhost:18293/",    

    //baseUrl: 'http://RFQ.clientbookingportal.test.edashboard.in/',
    //baseUrlMain: 'http://RFQ.ipsosbookingportal.test.edashboard.in/',

    //baseUrl: 'http://RFQ.clientbookingportal.edashboard.in/',
    //baseUrlMain: 'http://RFQ.ipsosbookingportal.edashboard.in/',

    //baseUrl: 'http://requestmanagementgoogle.excavateservices.com/',
    //baseUrl: 'http://requestmanagement.tatasky.excavateservices.com/',

    appVersion: "2.61",
    debugMode: false,
    get isApp()
    {
        return false;
        //return !document.URL.startsWith('http') || document.URL.startsWith('http://localhost:80');
    }
};

@Injectable()
export class AppConfig
{
    static isAppLoaded;

    static get baseUrl()
    {
        return globalConfig.baseUrl;
    }

    static get baseUrlMain()
    {
        return globalConfig.baseUrlMain;
    }

    static get isApp() //set from app.component because it is static and platform object is required here
    {
        if (globalConfig.isApp) //we will keep isApp true in custom.js in case of App
            return true;
        else
            return !document.URL.startsWith('http');
    }

    static get debugMode()
    {
        return globalConfig.debugMode;
    }

    static get appVersion() // Only for browser platform. For other we will fetch it from AppVserion Plugin
    {
        return globalConfig.appVersion;
    }

    static clientId = '099153c2625149bc8ecb3e85e03f0022';
    static dateFormat: string = 'dd/MM/yyyy';
    static googleAnalyticsId = 'UA-121839951-1';
    static codePushKeyStaging = 'x6ij99_eEsmbPxdLB0gdX-urtMTEa8053550-a86a-4c8d-bdb3-5095d476e9f8';
    static codePushKeyProduction = 'IUdd5zperkSrH-PytJKRX1Z8DXj7a8053550-a86a-4c8d-bdb3-5095d476e9f8';

    static get themes()
    {
        return themes;
    }
}
