export const MENU: any = [ 
    {
        title: 'Home',
        icon: {
            class: 'fas fa-plus-circle',
            bg: '#E61D2B',
            color: 'rgba(255,255,255, 0.9)'
        },
        path: '/home'
    },
    {
        title: 'New Request',
        icon: {
            class: 'fas fa-plus-circle',
            bg: '#E61D2B',
            color: 'rgba(255,255,255, 0.9)'
        },
        path: '/request/0'
    },
    {
        title: 'Approval Pending',
        icon: {
            class: 'fas fa-table',
            bg: '#E61D2B',
            color: 'rgba(255,255,255, 0.9)'
        },
        path: '/feed/1'
    },
    {
        title: 'Feedback Uploaded',
        icon: {
            class: 'fas fa-table',
            bg: '#E61D2B',
            color: 'rgba(255,255,255, 0.9)'
        },
        path: '/feed/3'
    },
    {
        title: 'Approved',
        icon: {
            class: 'fas fa-table',
            bg: '#E61D2B',
            color: 'rgba(255,255,255, 0.9)'
        },
        path: '/feed/2'
    },
    {
        title: 'Rejected',
        icon: {
            class: 'fas fa-table',
            bg: '#E61D2B',
            color: 'rgba(255,255,255, 0.9)'
        },
        path: '/feed/4'
    }
    ,
    {
        title: 'Feeds',
        icon: {
            class: 'fas fa-table',
            bg: '#E61D2B',
            color: 'rgba(255,255,255, 0.9)'
        },
        path: '/randomfeed'
    },
    {
        title: 'New Feed',
        icon: {
            class: 'fas fa-plus-circle',
            bg: '#E61D2B',
            color: 'rgba(255,255,255, 0.9)'
        },
        path: '/newrandomfeed'
    },
    {
        title: 'Logout',
        icon: {
            class: 'fas fa-sign-out-alt',
            bg: '#E61D2B',
            color: 'rgba(255,255,255, 0.9)'
        },
        path: '/login'
    }
];



