import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from './app.config';
import { GlobalService } from '../providers/global.service';
import * as jwt_decode from 'jwt-decode';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable()
export class TokenService
{
    constructor(private http: HttpClient, private storage: Storage)
    {

    }

    getTokenExpirationDate(token: string): Date
    {
        const decoded = jwt_decode(token);

        if (decoded.exp === undefined) return null;

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    isTokenExpired(token?: string): boolean
    {
        if (!token) return true;

        const date = this.getTokenExpirationDate(token);
        if (date === undefined) return false;
        return !(date.valueOf() > new Date().valueOf());
    }

    public refreshToken(refreshToken: any): Observable<any>
    {
        var d = "grant_type=refresh_token&refresh_token=" + refreshToken + '&client_id=' + AppConfig.clientId;
        return this.http.post(AppConfig.baseUrl + 'token', d).pipe(
            map((response: any) =>
            {
                var res = response.json();
                if (res && res.access_token)
                    this.setUser(res);
                return GlobalService.user;
            })
        );
    }

    public getAccessToken(): Promise<string>
    {
        return this.storage.get('user').then(
            (user: any) =>
            {
                if (!user)
                {
                    return null;
                }
                else if (this.isTokenExpired(user.access_token))
                {
                    this.refreshToken(user.refresh_token).subscribe(
                        user =>
                        {
                            return user.access_token;  //return new access_token
                        },
                        error =>
                        {
                            return user.access_token;       // if error send old access_token and then let it error 401
                        }
                    );
                }
                else
                {
                    return user.access_token;   //token not expired
                }
            });
    }

    public setUser(res: any): any
    {
        let user = jwt_decode(res.access_token);

        user.access_token = res.access_token;
        //if(user.Role)
        //    user.Role = JSON.parse(user.Role);
        //else
        //    user.Role={Id:'admin', Permissions:[]}
        //user.Permissions = [];

        //for (var j = 0; j < user.Role.Permissions.length; j++)
        //{
        //    user.Permissions.push(user.Role.Permissions[j]);
        //}

        this.storage.set('user', user);
        GlobalService.user = user;
        return user;
    } 
}
