import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { IonicService } from './ionic.service';
import { LogService } from './log.service';

@Injectable()
export class FileService
{
    gs = GenericService;
    extensionLists = { image: ['jpg', 'gif', 'bmp', 'png'], audio: ['m4a', 'mp3', 'wav'], video: ['m4v', 'avi', 'mpg', 'mp4', 'webm'] };

    constructor(private ls: LogService, private ions: IonicService)
    {
    }

    //here is the method is used to get content type of an bas64 data  
    getContentType(base64Data: any)
    {
        let block = base64Data.split(";");
        let contentType = block[0].split(":")[1];
        return contentType;
    }

    base64toBlob(dataURI, contentType)
    {
        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++)
        {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: contentType });
    }

    getFileName(url, withExt: boolean = true): string
    {
        let fileName: string = url.substring(url.lastIndexOf('/') + 1);
        let ext = "." + url.split('.').pop();
        return withExt ? fileName : `${fileName.replace(ext, "")}`;
    }

    getFileExtension(fileName: string)
    {
        return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2)
    }

    isImage(fileName: string)
    {
        let ext = this.getFileExtension(fileName);
        return this.extensionLists.image.find(x => x.endsWith(ext));
    }

    isAudio(fileName: string)
    {
        let ext = this.getFileExtension(fileName);
        return this.extensionLists.audio.find(x => x.endsWith(ext));
    }

    isVideo(fileName: string)
    {
        let ext = this.getFileExtension(fileName);
        return this.extensionLists.video.find(x => x.endsWith(ext));
    }

    getFileType(fileName: string)
    {
        if (this.isImage(fileName))
            return "image";
        else if (this.isAudio(fileName))
            return "audio";
        else if (this.isVideo(fileName))
            return "video";
        else
            return "other";
    }

    appendTimeStamp(fileName)
    {
        let ext = this.getFileExtension(fileName);
        fileName = GenericService.replaceAll(fileName, '.' + ext, '');

        let date = new Date();
        fileName = fileName + "_" + date.getDate() + (date.getMonth() + 1) + date.getFullYear() + date.getHours() + date.getMinutes() + date.getSeconds();
        if (ext)
            fileName += "." + ext;

        return fileName;
    }
}

