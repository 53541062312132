import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { AjaxService } from './ajax.service';
import { GlobalService } from './global.service';
import { NetworkService } from './network.service';
import { LogLevel } from './enum';
import { AppConfig } from './app.config';

@Injectable()
export class LogService
{
    constructor(private ajaxService: AjaxService)
    {
    }

    logDebug(msg)
    {
        if (AppConfig.debugMode)
            console.log(msg, LogLevel.Debug);
    }

    log(obj, level: LogLevel = LogLevel.Info, callBack = null, remarks = null)
    {
        try
        {
            let msg: string = '';
            if (typeof obj == 'string')
                msg = obj;
            else
            {
                if (obj.rejection)
                    obj = obj.rejection;

                let parsedObj = this.parse(obj);
                msg = JSON.stringify(parsedObj);
            }
            console.log(msg);
            if (msg.startsWith('ServerError:'))
                return;

            if (remarks)
                msg = remarks + ":" + msg;

            let log: any =
            {
                UId: GenericService.createUId(), Message: msg, Level: level, CreatedOn: GenericService.serializeDate(new Date())
            };
            if (GlobalService.user)
                log.CreatedBy = { Id: GlobalService.user.Id };

            if (!NetworkService.isOnline())
                this.postLog(log, callBack)
        }
        catch (err)
        {
            console.log(err);
        }
    }

    postLog(log, callBack)
    {
        this.ajaxService.callPostService('log', log, !GlobalService.user)
            .subscribe(() =>
            {
                if (callBack)
                    callBack();
            },
            () =>
            {
            });
    }

    parse(obj: any)
    {
        let parsedObj: any =
            {
                message: obj.message ? obj.message as string : JSON.stringify(obj)
            };

        // include HTTP status code
        if (obj.status != null)
            parsedObj.status = obj.status;

        // include stack trace
        if (obj.stack != null)
            parsedObj.stack = obj.stack;

        return parsedObj;
    }
}
