import { Events, MenuController, NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { AjaxService } from './ajax.service';
import { TokenService } from './token.service';
import { GlobalService } from './global.service';
import { Storage } from '@ionic/storage';
import { IonicService } from './ionic.service';

declare var globalConfig: any;

@Injectable()
export class AuthService
{
    constructor(private tokenService: TokenService, private ajaxService: AjaxService,
        private events: Events, private storage: Storage, private menu: MenuController, private navCtrl: NavController, private ionicService: IonicService)
    {

    }

    async isLoggedin()
    {
        GlobalService.user = GlobalService.user || await this.storage.get('user');
        GlobalService.projectId = GlobalService.projectId || await this.storage.get('projectId');

        if (!GlobalService.user)
            return false;
        if (!GlobalService.user.access_token)
        {
            GlobalService.user = null;
            return false;
        }

        var loggedIn = !this.tokenService.isTokenExpired(GlobalService.user.access_token);
        if (!loggedIn)
            GlobalService.user = null;

        return loggedIn;
    }

    public changeSurvey()
    {
        this.storage.set('user', GlobalService.user);
    }

    getUser(): Observable<any>
    {
        return from(this.storage.get('user'));
    }

    login(userName: string, password: string): Observable<boolean>
    {
        let data = "grant_type=password&userName=" + userName + "&password=" + password + '&client_id=099153c2625149bc8ecb3e85e03f0022';
        return this.ajaxService.callPostService('token', data, true, true).pipe(
            timeout(12000),
            map((response: Response) =>
            {
                var user = this.tokenService.setUser(response);
                GlobalService.user = user;
                return user;
            },err =>
            {                 
                this.ionicService.showErrorToast(err);
                //this.presentAlert();
            }));        
    }


    changePassword(currentPassword: string, newPassword: string): Observable<boolean>
    {
        var data: any = { CurrentPassword: currentPassword, NewPassword: newPassword };

        return this.ajaxService.callService('user/changePassword', false, 'PUT', data).pipe(
            map((response: Response) =>
            {
                return true;
            })
        );
    }

    async logout()
    {
        this.menu.close();
        this.menu.enable(false);
        GlobalService.user = null;
        await this.storage.remove('user');
        //this.navCtrl.navigateRoot('/login');
    }

    static hasRole(roleId, user = null): boolean
    {
        user = user || GlobalService.user;
        if (!user || !user.Role)
            return false
        else
        {
            return user.RoleId.toLowerCase() == roleId.toLowerCase();
        }
    }


    static isAdmin(user = null): boolean
    {
        user = user || GlobalService.user;
        if (!user || !user.Role)
            return false
        else
        {
            return (user.Role == "1");
        }
    }


    static hasPermission(permission, user = null): boolean
    {
        user = user || GlobalService.user;
        if (!user || !user.Role || !user.Role.Permissions)
            return false

        let hasPermission = user.Role.Permissions.find(p => p.Id == permission) ? true : false;
        return hasPermission;
    }
}
