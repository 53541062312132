import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from './log.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler
{
    constructor(private ls: LogService)
    {
        super();
    }

    handleError(error: any): void
    {
        this.ls.log(error, 4, ()=>
        {
            super.handleError(error)
        });
    }
}

