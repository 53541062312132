import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx'
import { Observable, BehaviorSubject } from 'rxjs';

export enum ConnectionStatus
{
    Online,
    Offline
}

export var CONNECTION_ERROR = 'Unable to connect to server. Please check your Internet connection and try again.';

@Injectable()
export class NetworkService
{
    public static status: ConnectionStatus;
    private static _status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(null);

    constructor(public network: Network)
    {
        NetworkService.status = navigator['onLine'] ? ConnectionStatus.Online : ConnectionStatus.Offline;
    }

    public initializeNetworkEvents(): void
    {
        /* OFFLINE */
        this.network.onDisconnect().subscribe(() =>
        {
           // alert('disconnected');
            if (NetworkService.status === ConnectionStatus.Online)
            {
                this.setStatus(ConnectionStatus.Offline);
            }
        })

        /* ONLINE */
        this.network.onConnect().subscribe(() =>
        {
           // alert('connected');
            if (NetworkService.status === ConnectionStatus.Offline)
            {
                this.setStatus(ConnectionStatus.Online);
            }
        })
    }

    public getNetworkType(): string
    {
        return this.network.type;
    }

    static getNetworkStatus(): Observable<ConnectionStatus>
    {
        return NetworkService._status.asObservable();
    }

    private setStatus(status: ConnectionStatus)
    {
        NetworkService.status = status;
        NetworkService._status.next(NetworkService.status);
    }

    static isOnline()
    {
        return NetworkService.status === ConnectionStatus.Online;
    }

    static isOffline()
    {
        return NetworkService.status === ConnectionStatus.Offline;
    }
}
