import { Injectable } from '@angular/core';
import { finalize, map } from 'rxjs/operators';
import { GenericService } from './generic.service';
import { IonicService } from './ionic.service';
import { AjaxService } from './ajax.service';
import { GlobalService } from './global.service';
import { AppConfig } from './app.config';
import { AuthGuard } from './auth-guard';

declare var $;

@Injectable()
export class DataService2
{
    dtOptions: any = {};
    serviceUrl: string;
    columns;
    table;
    dataTable;
    totalCount;
    items;
    mode = 'list';

    constructor(private ajaxService: AjaxService, private ions: IonicService, private ag: AuthGuard)
    {
    }

    setDtOptions()
    {
        this.items = [];
        this.dtOptions =
            {
                pagingType: 'full_numbers',
                paging: true,
                serverSide: true,
                processing: true,
                searching: true,
                columns: this.columns,
                ajax: (dtParams: any, callback) =>
                {
                    //let url = GenericService.addToQueryString(this.serviceUrl, 'surveyCode', GlobalService.user.surveyCode);
                    //url = GenericService.addToQueryString(url, '$top', dtParams.length);
                    //if (dtParams.start > 0)
                    //    url = GenericService.addToQueryString(url, '$skip', dtParams.start);
                    //else
                    //    url = GenericService.addToQueryString(url, '$inlinecount', 'allpages');

                    //if (dtParams.order && dtParams.order.length > 0 && this.serviceUrl.indexOf('response') == -1)
                    //{
                    //    let orderBy = '';
                    //    dtParams.order.forEach(x => orderBy += ", " + this.columns[x.column].data + ' ' + x.dir);
                    //    orderBy = orderBy.substring(2, orderBy.length);
                    //    url = GenericService.addToQueryString(url, '$orderby', orderBy);
                    //}

                    let url = this.serviceUrl;
                    url = GenericService.addToQueryString(url, 'pageSize', dtParams.length);
                    if (dtParams.start > 0)
                        url = GenericService.addToQueryString(url, 'pageNo', (dtParams.start / dtParams.length) + 1);
                    if (dtParams.search && dtParams.search.value)
                    {
                        url = GenericService.addToQueryString(url, 'filters', "name:" + dtParams.search.value);
                    }

                    //let url = "user/list";

                    this.items = [];
                    this.ajaxService.callGetService(url, true, true).pipe(
                        finalize(() =>
                        {
                            this.ions.hideSpinner();
                            this.ions.hideLoader();
                        })).subscribe((res: any) => 
                        {
                            let items = [];
                            if (res.Items && res.Items.length > 0 && Array.isArray(res.Items[0]))//BsonDocument returns Array of Array
                            {
                                res.Items.forEach((x, i) =>
                                {
                                    let obj = {};
                                    x.forEach((y, j) =>
                                    {
                                        obj[y._name] = y._value;
                                    });
                                    items.push(obj);
                                });
                            }
                            else if (res.Items)
                                items = res.Items || [];
                            else
                                items = res;

                            items.forEach(item =>
                            {
                                this.columns.forEach((col: any) =>
                                {
                                    item[col.data] = item[col.data] || null;
                                });
                            });

                            if (dtParams.start == 0)
                                this.totalCount = res.Total || res.length;


                            this.totalCount = !this.totalCount ? 0 : this.totalCount;
                            this.items = items;
                            callback({
                                recordsTotal: this.totalCount,
                                recordsFiltered: this.totalCount,
                                data: items,
                            });
                        });
                }, columnDefs: [{
                    "targets": -1,
                    "data": null,
                    "defaultContent": "<button class='btn-download'><ion-icon slot = 'icon-only' name = 'arrow-down'></ion-icon></button>"
                }]

            }
    }

    loadDataTable(serviceUrl, columns, table)
    {
        this.mode = 'list';
        this.serviceUrl = serviceUrl;
        this.columns = columns;
        this.table = table;
        this.totalCount = 0;

        this.setDtOptions();

        if (this.dataTable)
            this.dataTable.destroy();

        this.dataTable = $(this.table.nativeElement).DataTable(this.dtOptions);

        let that = this;

        $(this.table.nativeElement).on('click', 'button', function ()
        {
            var data = that.dataTable.row($(this).parents('tr')).data();
            location.href = AppConfig.baseUrlMain + 'download-file?fileName=' + data["SaveFileName"] + "&dirName=Files";
            //that.ag.navigate('/user-add-edit', { id: data["Id"] }, null);
        });
    }

    async downloadExcel(serviceUrl)
    {
        await this.ions.showLoader();
        let url = GenericService.addToQueryString(serviceUrl, 'surveyCode', GlobalService.user.surveyCode);
        this.ajaxService.callGetService(url).pipe(finalize(() =>
        {
            this.ions.hideLoader();
        })).subscribe(fileName =>
        {
            let fileNames = fileName.join("");
            location.href = AppConfig.baseUrl + 'download-excel?fileName=' + fileNames;
        })
    }

    async uploadExcel(file, url)
    {
        await this.ions.showLoader('Uploading file. Please wait...');
        //url = GenericService.addToQueryString(url, 'projectCode', GlobalService.user.surveyCode);
        this.ajaxService.callPostService(url, file[0])
            .subscribe((res) =>
            {
                this.mode = 'list';
                this.ions.showSuccessToast(res);
            },
                (err) =>
                {
                    this.ions.alertError(err);
                });
    }
}
