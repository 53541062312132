import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: './pages/login/login.module#LoginPageModule'
    },
    {
        path: 'landing-page',
        loadChildren: './pages/landing-page/landing-page.module#LandingPageModule'
    },
    {
        path: 'quantative-form',
        loadChildren: './pages/quantative-form/quantative-form.module#QuantativeFormModule'
    },
    {
        path: 'dashboard-client',
        loadChildren: './pages/dashboard-client/dashboard-client.module#DashboardClientPageModule'
    },
    {
        path: 'new-request',
        loadChildren: './pages/new-request/new-request.module#NewRequestPageModule'
    },
    {
        path: 'edit-request/:id',
        loadChildren: './pages/edit-request/edit-request.module#EditRequestPageModule'
    },
    {
        path: 'edit-request',
        loadChildren: './pages/edit-request/edit-request.module#EditRequestPageModule'
    },
    {
        path: 'request-details',
        loadChildren: './pages/project-details/project-details.module#ProjectDetailsPageModule'
    },
    {
        path: 'request-list/:status',
        loadChildren: './pages/request-list/request-list.module#RequestListPageModule'
    },
    {
        path: 'profile',
        loadChildren: './pages/profile/profile.module#ProfilePageModule'
    }
];

@NgModule({
    // 
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
