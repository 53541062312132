import {Injectable, Pipe, PipeTransform} from '@angular/core';

import { DatePipe } from '@angular/common';

@Pipe({
    name: 'format'
})
@Injectable()
export class FormatPipe implements PipeTransform
{
    transform(value: any, type: string): string
    {
        var dateFormat = "";
        if (type == 'bool')
        {

            if (value == true || value == 1 || value == "1" || value == "TRUE" || value == "true")
                return "Yes";
            else
                return "No";
        }
        else if (type == 'date')
        {
            return new DatePipe("en-US").transform(value, dateFormat);
        }

        else if (type == 'datetime')
        {
          return new DatePipe("en-US").transform(value, dateFormat);
        }
        else if (type == 'integer')
        {
            if (isNaN(value))
                return "";
            var num = Number(value);
            return Math.round(num).toString();
        }
        else if (type == 'two-decimal')
        {
            if (isNaN(value))
                return "";
            var num = parseFloat(value);
            return num.toFixed(2);
        }
        else
            return value;
    }
}
