import { Injectable } from '@angular/core';
import { finalize, map } from 'rxjs/operators';
import { GenericService } from './generic.service';
import { IonicService } from './ionic.service';
import { AjaxService } from './ajax.service';
import { GlobalService } from './global.service';
import { AppConfig } from './app.config';

declare var $;

@Injectable()
export class DataService
{
    dtOptions: any = {};
    serviceUrl: string;
    columns;
    table;
    dataTable;
    totalCount;
    items;

    constructor(private ajaxService: AjaxService, private ions: IonicService)
    {
    }

    setDtOptions()
    {
        this.items = [];
        this.dtOptions =
            {
                pagingType: 'full_numbers',
                serverSide: true,
                processing: true,
                searching: false,
                columns: this.columns,
                ajax: (dtParams: any, callback) =>
                {
                    let url = this.serviceUrl;
                    url = GenericService.addToQueryString(url, '$top', dtParams.length);

                    if (dtParams.start > 0)
                        url = GenericService.addToQueryString(url, '$skip', dtParams.start);
                    else
                        url = GenericService.addToQueryString(url, '$inlinecount', 'allpages');

                    if (dtParams.order && dtParams.order.length > 0 && this.serviceUrl.indexOf('response') == -1)
                    {
                        let orderBy = '';
                        dtParams.order.forEach(x => orderBy += ", " + this.columns[x.column].data + ' ' + x.dir);
                        orderBy = orderBy.substring(2, orderBy.length);
                        url = GenericService.addToQueryString(url, '$orderby', orderBy);
                    }

                    this.items = [];
                    this.ajaxService.callGetService(url).pipe(
                        finalize(() =>
                        {
                            this.ions.hideSpinner();
                            this.ions.hideLoader();
                        })).subscribe((res: any) => 
                        {
                            let items = [];
                            if (res.Items.length>0 && Array.isArray(res.Items[0]))//BsonDocument returns Array of Array
                            {
                                res.Items.forEach((x,i) =>
                                {
                                    let obj = {};
                                    x.forEach((y,j) =>
                                    {
                                        obj[y._name] = y._value;
                                    });
                                    items.push(obj);
                                });
                            }
                            else
                                items = res.Items || [];

                            items.forEach(item =>
                            {
                                this.columns.forEach((col: any) =>
                                {
                                    item[col.data] = item[col.data] || null;
                                });
                            });

                            if (res.Count)
                                this.totalCount = res.Count;

                            this.items = items;
                            callback({
                                recordsTotal: this.totalCount,
                                recordsFiltered: this.totalCount,
                                data: items,
                            });
                        });
                },
        }
    }

    loadDataTable(serviceUrl, columns, table)
    {
        this.serviceUrl = serviceUrl;
        this.columns = columns;
        this.table = table;
        this.totalCount = 0;

        this.setDtOptions();

        if (this.dataTable)
            this.dataTable.destroy();

        this.dataTable = $(this.table.nativeElement).DataTable(this.dtOptions);
    }

    async downloadExcel(serviceUrl)
    {
        await this.ions.showLoader();
        let url = GenericService.addToQueryString(serviceUrl, 'surveyCode', GlobalService.user.surveyCode);
        this.ajaxService.callGetService(url).pipe(finalize(() =>
        {
            this.ions.hideLoader();
        })).subscribe(fileName =>
        {
            location.href = AppConfig.baseUrl + 'download-excel?fileName=' + fileName;
        })
    }

    async uploadExcel(file, url)
    {
        await this.ions.showLoader('Uploading file. Please wait...');

        url = GenericService.addToQueryString(url, 'surveyCode', GlobalService.user.surveyCode);
        this.ajaxService.callPostService(url, file[0])
            .subscribe((res) =>
            {
                this.ions.showSuccessToast(res);
            },
            (err) =>
            {
                this.ions.alertError(err);
            });
    }
}
