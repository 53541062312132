import { Injectable } from '@angular/core';
import { LoadingController, ToastController, AlertController} from '@ionic/angular';
import { LogService } from './log.service';
import { GenericService } from './generic.service';
import { LogLevel } from './enum';
import { GlobalService } from './global.service';
import { NetworkService, CONNECTION_ERROR } from './network.service';
import { AppConfig } from './app.config';
import { from } from 'rxjs';

@Injectable()
export class IonicService
{
    isLoading = false;

    loader: HTMLIonLoadingElement;
    subscription: any;
    searchText: string;
    dataFetcherEvent: any;

    constructor(private loadingCtrl: LoadingController, private toastCtrl: ToastController,
        public alertCtrl: AlertController, private ls: LogService)
    {

    }

   async showLoader(text:string=null, allowCancel=false)
    {
        if (this.loader)
            this.loader.textContent = text;
        else
        {
            text = text || 'Please wait a moment...';
            if (allowCancel)
                this.loader = await this.loadingCtrl.create({ message: text, showBackdrop: true });
            else
                this.loader = await this.loadingCtrl.create({ message: text });
            return await this.loader.present();
        }
    }

    async hideLoader()
    {
        if (this.loader && this.loader.dismiss)
        {
            await this.loader.dismiss();
            this.loader = null;
        }
        //this.loadingCtrl.dismiss();
   }

    async presentLoader() {
        this.isLoading = true;
        return await this.loadingCtrl.create({
         
        }).then(a => {
            a.present().then(() => {
                //console.log('presented');
                if (!this.isLoading) {
                    a.dismiss().then(
                        //console.log('abort presenting');
                    );
                }
            });
        });
    }

    async dismissLoader() {
        this.isLoading = false;
        return await this.loadingCtrl.dismiss().then(
            //() => console.log('dismissed')
        );
    }

    get spinner()
    {
        return GlobalService.spinner;
    }

    showSpinner()
    {
        GlobalService.spinner = true;
    }

    hideSpinner()
    {
        GlobalService.spinner = false;
    }

   async showToast(text, position:any = 'top', cssClass = null)
    {
       await this.hideLoader();
       await this.hideSpinner();
        var toast = await this.toastCtrl.create({
            message: text,
            duration: 3000,
            position: position,
            cssClass: cssClass
        });
       await toast.present();
    }

    async showSuccessToast(text:string , position = null)
    {
        await this.showToast(text, position, 'success-toast');
    }

    async showErrorToast(err: any, position = null, errorLevel: LogLevel = null)
    {
        let msg = GenericService.fetchErrorMessage(err);
        await this.showToast(msg, position, 'error-toast');
        if (errorLevel != 0)
            this.ls.log(err);
    }

   async alert(text: string, cssClass=null)
    {
       await this.hideLoader();
       await this.hideSpinner();
       let obj:any = {
           message: text,
           buttons: ['Ok'],
       };
       if (cssClass)
           obj.cssClass= cssClass

        let alert = await this.alertCtrl.create(obj);
        await alert.present();
   }

   async alertSuccess(text: string)
    {
      await this.alert(text, 'alert-success');
    }

    async alertError(err: any, remarks:string=null, log=true)
    {
        await this.hideLoader();
        await this.hideSpinner();

        let msg: string = GenericService.fetchErrorMessage(err);
        if (msg.startsWith('ServerError: '))
        {
            msg = msg.replace('ServerError: ', '');
            log = false;
        }
        await this.alert(remarks || msg, 'alert-error');
        if (log)
            this.ls.log(err);
    }

    showAndLogError(err, remarks=null, silentMode=false)
    {
        remarks = remarks || "Something went wrong";
        if (err == CONNECTION_ERROR)
            remarks = err;
        if (!silentMode) this.alertError(err, remarks, true);
        else
            this.ls.log(err, LogLevel.Error, null, remarks);
        this.hideLoader();
        return err;
    }

    throwCustomError(err, remarks, logErr=true)
    {
        if (err == CONNECTION_ERROR)
            remarks = err;
        remarks = remarks || "Something went wrong";
        if (logErr)
            this.ls.log(err, LogLevel.Error, null, remarks);

        throw new Error(err);
    }

    async alertEnableData()
    {
        await this.hideLoader();
        await this.hideSpinner();

        if (NetworkService.isOffline())
        {
            let prompt = await this.alertCtrl.create({
                header: 'Turn on Mobile data?',
                message:'Connect to a Wi-Fi network or turn on Mobile data.',
                buttons: [
                    {
                        text: "Ok",
                    }
                ]
            });
            await prompt.present();
        }
    }
}
