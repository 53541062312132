import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as Color from 'color';
import { Storage } from '@ionic/storage';
import { retry } from 'rxjs/operators';

@Injectable()
export class ThemeService
{
    //constructor(@Inject(DOCUMENT) private document: Document, private storage: Storage)
    //{
    //    storage.get('theme').then(cssText =>
    //    {
    //        this.setGlobalCSS(cssText);
    //    });
    //}

    //// Override all global variables with a new theme
    ////setTheme(theme)
    ////{
    ////    const cssText = this.generateCssText(theme);
    ////    this.setGlobalCSS(cssText);
    ////    this.storage.set('theme', cssText);
    ////}

    ////// Define a single CSS variable
    ////setVariable(name, value)
    ////{
    ////    this.document.documentElement.style.setProperty(name, value);
    ////}

    //private setGlobalCSS(css: string)
    //{
    //    this.document.documentElement.style.cssText = css;
    //}

    //get storedTheme()
    //{
    //    return this.storage.get('theme');
    //}

    //generateCssText(colors)
    //{
    //    let convertHexToRgb = this.convertHexToRgb;

    //    let cssText = `
    //--ion-background-color: #ffffff;
    //--ion-background-color-rgb: 255,255,255;
    //--ion-text-color: ${colors.base.dark};
    //--ion-text-color-rgb: ${convertHexToRgb(colors.base.dark)};
    //--ion-toolbar-background: ${colors.base.primary};
    //--ion-toolbar-background-rgb: ${convertHexToRgb(colors.base.primary)};
    //--ion-toolbar-color: ${colors.contrast.primary};
    //--ion-toolbar-color-rgb: ${convertHexToRgb(colors.contrast.primary)};
    //`
    //    Object.keys(colors.base).forEach(key =>
    //    {
    //        let color = colors.base[key];
    //        let contrast = colors.contrast[key];

    //        cssText += ` --ion-color-${key}: ${color};
    //--ion-color-${key}-rgb: ${convertHexToRgb(color)};
    //--ion-color-${key}-contrast: ${contrast};
    //--ion-color-${key}-contrast-rgb: ${convertHexToRgb(contrast)};
    //--ion-color-${key}-shade:  ${Color(color).darken(0.1)};
    //--ion-color-${key}-tint:  ${Color(color).lighten(0.1)};`
    //    });

    //    return cssText;
    //}

    //convertHexToRgb(hex)
    //{
    //    hex = hex.replace('#', '');
    //    let r = parseInt(hex.substring(0, 2), 16);
    //    let g = parseInt(hex.substring(2, 4), 16);
    //    let b = parseInt(hex.substring(4, 6), 16);

    //    let result = r + ',' + g + ',' + b;
    //    return result;
    //}
}
