import { Injectable } from '@angular/core';
import { IonicService } from './ionic.service';

@Injectable()
export class ImageService
{
    constructor(private ions:IonicService) { }

    loadImage(src: string): Promise<HTMLImageElement>
    {
        return new Promise((resolve, reject) =>
        {
            var tempImg = new Image();
            tempImg.addEventListener('load', e => resolve(tempImg));
            tempImg.addEventListener('error', () =>
            {
                reject(new Error(`Failed to load image: ${src}`));
            });
            tempImg.src = src;
        });
    }

    resizeImage(image: HTMLImageElement, longSideMax)
    {
        // Get image size and aspect ratio.
        var targetWidth = image.width;
        var targetHeight = image.height;
        var aspect = image.width / image.height;

        // Calculate shorter side length, keeping aspect ratio on image.
        // If source image size is less than given longSideMax, then it need to be
        // considered instead.
        if (image.width > image.height)
        {
            longSideMax = Math.min(image.width, longSideMax);
            targetWidth = longSideMax;
            targetHeight = longSideMax / aspect;
        }
        else
        {
            longSideMax = Math.min(image.height, longSideMax);
            targetHeight = longSideMax;
            targetWidth = longSideMax * aspect;
        }

        // Create canvas of required size.
        var canvas = document.createElement('canvas');
        canvas.width = targetWidth;
        canvas.height = targetHeight;

        var ctx = canvas.getContext("2d");
        // Take image from top left corner to bottom right corner and draw the image
        // on canvas to completely fill into.
        ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, targetWidth, targetHeight);

        let url = canvas.toDataURL("image/jpeg");
        return url;
    }

}